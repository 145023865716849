import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { colleagueStatus, status } from 'constants/Helpers';
import Lightbox from 'react-image-lightbox';
// SVG
import StarIcon from 'headflorist/assets/images/svg/StarIcon';
import WarningIcon from 'headflorist/assets/images/svg/WarningIcon';
// Components
import ChangeStatusBtn from 'headflorist/components/orders/ChangeStatusBtn';
// Actions
import { setOrderDetailsAllData } from 'headflorist/store/orderDetails/OrderDetailsActions';
// Selectors
import { getFirebasePermissions } from 'store/app/AppSelectors';
import { getQcImagesData } from 'headflorist/store/orderDetails/OrderDetailsSelectors';
// Localstorage
import { getFromLocalStorage } from 'headflorist/helpers/LocalStorage';

const OrderDetailsCard = ({ orderDetails, hasSubmittedBefore, hasDeviation }) => {
  const dispatch = useDispatch();
  const firebasePermissions = useSelector((state) => getFirebasePermissions({ state }));
  const qcImages = useSelector((state) => getQcImagesData({ state }));
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const opsID = getFromLocalStorage('opsId');
  const editDeviationEnabled = firebasePermissions?.enable_edit_deviation?.filter(
    (ops) => ops.id === parseInt(opsID)
  )?.[0]?.enable;

  const fetchOrderDetails = (orderId) => {
    dispatch(setOrderDetailsAllData(orderId));
  };

  return (
    <div className="card orderDetails-card">
      <div className="card-body">
        <div className="order-slot-col">
          <h6 className="delivery-date">
            {moment(orderDetails?.deliveryDate).format('DD/MM/YYYY')}
          </h6>
          <h6 className="delivery-slot">{orderDetails?.deliveryTimeName}</h6>
        </div>
        <div className="order-id-col">
          <h6 className="order-id-text">Order</h6>
          <h6 className="order-id-value">{orderDetails?.id}</h6>
          {orderDetails?.productionLine === 1 && <StarIcon />}
          {orderDetails?.isNwDelivery ? <div className="nw-order">NW</div> : null}
        </div>
        {orderDetails?.deliveryZone ? (
          <div className="delivery-zone-col">
            <h6 className="zone-text">Zone</h6>
            <h6 className="zone-value">{orderDetails?.deliveryZone}</h6>
          </div>
        ) : null}
        {orderDetails?.note ? (
          <div className="order-notes-col">
            <h6 className="notes-text">Note</h6>
            <h6 className="notes-value">{orderDetails?.note ?? ''}</h6>
          </div>
        ) : null}
        {orderDetails?.floristDto && orderDetails?.floristDto?.name && (
          <div className="assign-florist-col">
            <h6 className="florist-name">{orderDetails?.floristDto?.name ?? ''}</h6>
            <h6 className="florist-status">
              {orderDetails?.preparationStatus !== null
                ? colleagueStatus[orderDetails?.preparationStatus]
                : ''}
            </h6>
          </div>
        )}
        <div
          className={`order-status-col ${
            Array.isArray(status[orderDetails?.preparationStatus ?? 6])
              ? 'order-status-col-btns'
              : ''
          }`}
        >
          <ChangeStatusBtn
            item={orderDetails}
            fetchMoreData={fetchOrderDetails}
            details={true}
            showAllCTA={true}
            hasSubmittedBefore={hasSubmittedBefore}
            hasDeviation={hasDeviation}
          />
        </div>
        {orderDetails?.orderType === 10 && orderDetails?.partnerName === 'Talabat' && (
          <div className="talabat-order-tag">
            <p>Talabat Order</p>
          </div>
        )}
        {qcImages?.length > 0 && (
          <div className="qc-images-wrapper">
            <h4>Quality Check</h4>
            <div className="qc-attachments">
              {qcImages?.map((el) => (
                <img
                  key={el}
                  className="qc-img"
                  src={el}
                  alt="qcImg"
                  onClick={() => {
                    setIsImgOpen(true);
                    setImageSrc(el);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        <div className="first-order-wrapper">
          {(orderDetails?.firstOrder === 0 || orderDetails?.firstOrder === 2) && (
            <div className="first-order-style" style={{ backgroundColor: '#00b2ff' }}>
              1st-S
            </div>
          )}
          {(orderDetails?.firstOrder === 1 || orderDetails?.firstOrder === 2) && (
            <div className="first-order-style" style={{ backgroundColor: '#ffa800' }}>
              1st-R
            </div>
          )}
        </div>
      </div>
      {editDeviationEnabled &&
        orderDetails?.preparedPreviously &&
        orderDetails?.preparationStatus !== 0 && (
          <div className="header-disclaimer order-details-disclaimer">
            <WarningIcon />{' '}
            <p>This order was prepared once. Check with supervisor before moving ahead.</p>
          </div>
        )}
      {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
    </div>
  );
};

export default OrderDetailsCard;
